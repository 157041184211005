'use strict';

const cache = {
    $body: $('body'),
}

function trackingUpdateQuantityFromCart(data) {
    const currency = data.basketModel.totals.currency;

    const items = data.productToPush;
    cache.$body.trigger('Tracking:UpdateQuantityProduct', { items, currency })
}

module.exports = function() {
    cache.$body
        .on('product:AfterUpdateQuantityFromCart', function (e, context) {
            if (!context.error)
                trackingUpdateQuantityFromCart(context);
        })
        .on('search:preview', function (e) {
            var searchPhrase = $('.js-search-field:visible').val();
            var searchResultsCount = $('.js-products-suggested:not(.hide)').data('results-count');
            cache.$body.trigger('Tracking:SearchPreview', { searchPhrase, searchResultsCount })
        })
};
