'use strict'

/**
 * Function to create a collection with all Items in current basket with attributes to push for Matomo.
 * @param cartItems
 * @returns {*}
 */
function createCartItems(cartItems) {
    var totalPrice=0;
    const items = cartItems.map(function (prod) {
        const item = {
            item_id: prod.id.slice(2),
            item_name: prod.productName,
            discount: prod.price.list ? (prod.price.list.value - prod.price.sales.value) : 0,
            currency: prod.price.sales.currency,
            item_brand: prod.brand,
            item_category: prod.primaryCategory.parentCategory,
            item_category2: prod.primaryCategory.name,
            price: prod.price.list ? prod.price.list.value : prod.price.sales.value,
            quantity: prod.selectedQuantity,
        };

        if (prod.promotions && prod.promotions.length) {
            item.promotion_id = prod.promotions[0].id;
            item.promotion_name = prod.promotions[0].name;
        }

        return item;
    });

    if (items && items.length > 0) items.forEach(item => totalPrice += (item.price - item.discount) * item.quantity);

    return {
        items: items,
        totalPrice: totalPrice
    }
}

module.exports = {
    createCartItems: createCartItems
}