'use strict';

const cache = {
    $body: $('body')
}

module.exports = function () {
    cache.$body.on('click', '.dropdown.country-selector', function () {
        const $dropCountrySelector = $('.dropdown-menu.dropdown-country-selector');
        const $this = $(this);

        if ($dropCountrySelector.hasClass('show')) {
            $dropCountrySelector.removeClass('show');
            $this.removeClass('show');
        } else {
            $dropCountrySelector.addClass('show');
            $this.addClass('show');
        }
    });
}
