'use strict';

const { mtmGeneralEvent, mtmPageView, mtmSearchResult, mtmProductImpression, mtmProductFilters,
    mtmUserImpression, mtmCouponApplied, mtmContactFormSubmit, mtmNewsletterSubscription,
    mtmVerifyCap, mtmDownloadCatalog, mtmRegistrationEvent, mtmUserLogin } = require('./tracking');
const { createCartItems } = require('./mtmHelpers');

const cache = {
    $body: $('body'),
    $categoryName: $('.js-category-name'),
    $searchTerm: $('.js-search-term')
}

module.exports = function () {
    // intercept login from oauth
    const urlParams = new URLSearchParams(window.location.search);
    const oauthLogin = urlParams.get('oauthLogin');
    const oauthRedirect = urlParams.get('redirect');

    if (oauthLogin) {
        mtmUserImpression({
            event: oauthRedirect === 'checkout' ? 'checkout_user_login' : 'user_login',
            method: oauthLogin
        });
    }

    cache.$body
        .on('Tracking:SearchPreview', (event, context) => {
            mtmSearchResult({
                event: 'mtm.search.preview',
                search_phrase: context.searchPhrase,
                search_result_count: context.searchResultsCount
            });
        })
        .on('Tracking:ProductsImpression', (event, context) => {
            if(cache.$categoryName.length > 0) {
                mtmProductImpression({
                    event: 'view_category',
                    category: cache.$categoryName.data('cat')
                });
            } else if(cache.$searchTerm.length > 0) {
                mtmSearchResult({
                    event: 'mtm.search',
                    search_phrase: cache.$searchTerm.data('search-term'),
                    search_result_count: cache.$searchTerm.data('search-count')
                });
            }
        })
        .on('Tracking:CheckoutLogin', (event) => {
            mtmGeneralEvent({
                    event: 'checkout_login'
                });
        })
        .on('Tracking:404', (event) => {
            mtmGeneralEvent({
                event: 'status_404'
            });
        })
        .on('Tracking:ViewPage', (event, context) => {
            mtmPageView({
                event: 'virtualPageView',
                page_type: context.page_type
            });
        })
        .on('Tracking:SelectAllergens', (event, context) => {
            mtmProductFilters({
                selected_items: context.selected_items
            });
        })
        .on('Tracking:SelectProduct', (event, context) => {
            mtmProductImpression({
                event: 'select_item',
                items: [context.product]
            });
        })
        .on('Tracking:ProductDetail', (event, context) => {
            mtmProductImpression({
                event: 'view_item',
                items: [context.product]
            });
        })
        .on('Tracking:AddProduct', (event, context) => {
            var data = context.data;
            var model = createCartItems(data.productToPush);
            mtmProductImpression({
                event: 'add_to_cart',
                items: model.items,
                totalPrice: data.cart.totals.subTotalPrice,
                currency: context.currency
            });
        })
        .on('Tracking:RemoveProduct', (event, context) => {
            var model = createCartItems(context.cartItemsRemaining);
            mtmProductImpression({
                event: 'remove_from_cart',
                items: model.items,
                totalPrice: context.totalPrice,
                currency: context.currency
            });
        })
        .on('Tracking:UpdateQuantityProduct', (event, context) => {
            var model = createCartItems(context.items);
            mtmProductImpression({
                event: 'change_cart',
                items: model.items,
                totalPrice: model.totalPrice,
                currency: context.currency
            });
        })
        .on('Tracking:AddToWishlist', (event, context) => {
            mtmProductImpression({
                event: 'add_to_wishlist',
                items: context.items,
            });
        })
        .on('Tracking:Login', (event, context) => {
            mtmUserImpression({
                user_id: context.userID,
                event: context.event,
                method: context.method
            });

            mtmUserLogin({
                event: 'mtm.user_login'
            });
        })
        .on('Tracking:ViewRegistration', (event, context) => {
            mtmRegistrationEvent({
                event: 'mtm.user_registration',
                step: context.step
            });
        })
        .on('Tracking:Registration', (event, context) => {
            mtmUserImpression({
                event: context.event,
                method: context.method,
                customer_type: context.customer_type
            });
        })
        .on('Tracking:RegistrationSuccess', (event, context) => {
            mtmRegistrationEvent({
                event: 'mtm.user_registration',
                step: 2
            });
        })
        .on('Tracking:NewsletterSubscription', (event, context) => {
            mtmNewsletterSubscription();
        })
        .on('Tracking:ZipCode', (event, context) => {
            mtmVerifyCap({ cap: context });

            //ATTUALMENTE NON UTILIZZATA IN CH
        })
        .on('Tracking:ViewCart', (event, context) => {
            mtmProductImpression({
                event: context.event,
                items: context.items,
                totalPrice: context.totalPrice,
                currency: context.currency,
                coupons: context.coupons
            });
        })
        .on('Tracking:Checkout', (event, context) => {
            mtmProductImpression({
                event: context.event,
                items: context.items,
                totalPrice: context.totalPrice,
                currency: context.currency,
                coupons: context.coupons,
                payment_type: context.payment_type
            });
        })
        .on('Tracking:AppliedCoupon', (event, context) => {
            mtmCouponApplied({
                coupon: context.coupon
            });
        })
        .on('Tracking:SubmitForm', (event, context) => {
            const form_purpose = context;
            mtmContactFormSubmit({ form_purpose });
        })
        .on('Tracking:DownloadCatalog', (event, context) => {
            const file_name = context;
            mtmDownloadCatalog({ file_name });
        })
        .on('Tracking:Purchase', (event, context) => {
            mtmProductImpression(context);
        });
}
